.jaws {
  position: relative;
  width: 182px;
  height: 165px;
  left: 20px;
}

.upperJaw {
  position: absolute;
  left: 0;
}

.lowerJaw {
  position: absolute;
}

.bite {
  position: absolute;
  left: 60px;
  z-index: 1;
}

.prepToggle {
  position: absolute;
}
