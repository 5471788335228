.pluginWrapper {
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.container {
  padding: 15px;
  border: 3px solid transparent;
  margin: 10px 0 10px 0;
}

.rightControls {
  margin-top: 15px;
  z-index: 1;
}

.imageFrame {
  margin-top: 15px;
}

.dataContainer {
  height: 300px;
  overflow: auto;
  border: solid gray 1px;
  background-color: whitesmoke;
  padding: 20px;
  margin: 10px;
}

.tableContainer {
  background-color: rgb(242, 248, 251);
  display: flex;
  flex-direction: row;
}

.statusContainer {
  padding: 10px;
  text-align: center;
  color: red;
  font-weight: bold;
}

.labelingWrapper {
  border: 3px red solid;
  border-radius: 10px;
  margin: 10px 0 10px 0;
}

.legendWrapper {
  padding: 7px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgb(214, 254, 191);
  width: 255px;
  height: fit-content;
}

.statusWrapper {
  flex: 1;
}

.wrapperFlex1 {
  padding: 10px;
  margin: 0px 10px 0 10px;
  flex: 1;
}

.wrapperFlex2 {
  padding: 10px;
  flex: 2;
}

.statusDetail {
  font-weight: bold;
  margin-left: 8px;
}

.statusTitle {
  margin-left: 2px;
}

.categoryTitle {
  font-weight: bold;
  text-transform: uppercase;
}

.markerZindex {
  z-index: 98;
}

.appVersion {
  position: absolute;
  bottom: 30px;
  color: transparent;
}

.gotobtn {
  top: 20px;
  right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background: #f1efef;
  border: 1px solid grey;
  z-index: 1;
  margin: 5px;
}

.customUi {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.dialogBtns {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.dialogBtn {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.dialogTextArea {
  width: 100%;
  resize: none;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: #666;
}
