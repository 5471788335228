.container {
  touch-action: none;
  cursor: pointer;
  flex: 1;
  flex-direction: row;
  display: flex;
}
.buttonContainer {
  user-select: none;
  text-align: center;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(199, 215, 238);
  border: 1px solid rgb(46, 104, 189);
  border-radius: 10px;
  width: 10;
}
.img {
  touch-action: none;
  user-select: none;
}
