.completeTaskButton {
  background: #2561a8;
  background-image: linear-gradient(to bottom, #205593, #2561a8);
  border-radius: 10px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 100;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #1c497e 1px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  height: 41px;
  width: 77px;
  margin: 10px;
}

.completeTaskButton:hover {
  background: #2a6dbd;
  background-image: linear-gradient(to bottom, #2a6dbd, #2f79d1);
}

.completeTaskButton:disabled {
  background: #193a63;
  color: gray;
}

.completeTaskButtonTooth {
  margin-left: 0px;
}
