.controls {
  display: flex;
  margin-bottom: 5px;
  margin-top: -35px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-between;
}

.checkbox {
  order: 3;
}

.rightCheckbox {
  align-items: center;
}

.searchText {
  order: 2;
  width: 100px;
}

.searchText:focus {
  outline-color: gray;
}

::placeholder {
  font-style: italic;
  color: gray;
}
