.taskButton {
  width: 93px;
}

.selectedTask {
  background: #2561a8;
  color: white;
}

.consistentTask {
  background-image: linear-gradient(to bottom, #23ad25ad, #5dd3455e);
}
