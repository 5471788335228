.button {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: 0;
  user-select: none;
}

.button::-moz-focus-inner {
  border-style: 'none';
}

.disabled {
  cursor: default;
}
