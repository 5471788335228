.prepToggle {
  background-size: contain;
  background-repeat: no-repeat;
}

.ada1 {
  width: 31px;
  height: 36px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 23px 28px;
  left: -1px;
  top: 127px;
}
.ada2 {
  width: 34px;
  height: 36px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 26px 28px;
  left: -2px;
  top: 101px;
}
.ada3 {
  width: 34px;
  height: 34px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 26px 26px;
  left: 3px;
  top: 76px;
}
.ada4 {
  width: 31px;
  height: 31px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 23px 23px;
  left: 9px;
  top: 56px;
}
.ada5 {
  width: 33px;
  height: 30px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 25px 22px;
  left: 16px;
  top: 37px;
}
.ada6 {
  width: 33px;
  height: 29px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 25px 21px;
  left: 26px;
  top: 20px;
}
.ada7 {
  width: 29px;
  height: 29px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 21px 21px;
  left: 42px;
  top: 6px;
}
.ada8 {
  width: 34px;
  height: 35px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 26px 27px;
  left: 60px;
  top: -3px;
}
.ada9 {
  width: 34px;
  height: 35px;
  background-position: left 4px top 4px;
  background-size: 26px 27px;
  left: 85px;
  top: -2px;
}
.ada10 {
  width: 29px;
  height: 35px;
  background-position: left 4px top 4px;
  background-size: 21px 27px;
  left: 108px;
  top: 4px;
}
.ada11 {
  width: 33px;
  height: 29px;
  background-position: left 4px top 4px;
  background-size: 25px 21px;
  left: 121px;
  top: 21px;
}
.ada12 {
  width: 37px;
  height: 30px;
  background-position: left 4px top 4px;
  background-size: 29px 22px;
  left: 128px;
  top: 38px;
}
.ada13 {
  width: 31px;
  height: 31px;
  background-position: left 4px top 4px;
  background-size: 23px 23px;
  left: 141px;
  top: 56px;
}
.ada14 {
  width: 34px;
  height: 34px;
  background-position: left 4px top 4px;
  background-size: 26px 26px;
  left: 144px;
  top: 77px;
}
.ada15 {
  width: 34px;
  height: 36px;
  background-position: left 4px top 4px;
  background-size: 26px 28px;
  left: 149px;
  top: 100px;
}
.ada16 {
  width: 30px;
  height: 36px;
  background-position: left 4px top 4px;
  background-size: 22px 28px;
  left: 151px;
  top: 127px;
}
.ada17 {
  width: 34px;
  height: 39px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 26px 31px;
  left: 148px;
  top: -2px;
}
.ada18 {
  width: 36px;
  height: 39px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 28px 31px;
  left: 145px;
  top: 27px;
}
.ada19 {
  width: 37px;
  height: 36px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 29px 28px;
  left: 140px;
  top: 57px;
}
.ada20 {
  width: 32px;
  height: 31px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 24px 23px;
  left: 136px;
  top: 83px;
}
.ada21 {
  width: 32px;
  height: 32px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 24px 24px;
  left: 127px;
  top: 103px;
}
.ada22 {
  width: 31px;
  height: 27px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 23px 19px;
  left: 114px;
  top: 122px;
}
.ada23 {
  width: 26px;
  height: 28px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 18px 20px;
  left: 102px;
  top: 130px;
}
.ada24 {
  width: 26px;
  height: 29px;
  transform: scaleX(-1);
  background-position: left 4px top 4px;
  background-size: 18px 21px;
  left: 85px;
  top: 133px;
}
.ada25 {
  width: 26px;
  height: 29px;
  background-position: left 4px top 4px;
  background-size: 18px 21px;
  left: 68px;
  top: 133px;
}
.ada26 {
  width: 26px;
  height: 28px;
  background-position: left 4px top 4px;
  background-size: 18px 20px;
  left: 51px;
  top: 130px;
}
.ada27 {
  width: 31px;
  height: 27px;
  background-position: left 4px top 4px;
  background-size: 23px 19px;
  left: 34px;
  top: 122px;
}
.ada28 {
  width: 32px;
  height: 32px;
  background-position: left 4px top 4px;
  background-size: 24px 24px;
  left: 21px;
  top: 102px;
}
.ada29 {
  width: 33px;
  height: 31px;
  background-position: left 4px top 4px;
  background-size: 25px 23px;
  left: 11px;
  top: 83px;
}
.ada30 {
  width: 34px;
  height: 36px;
  background-position: left 4px top 4px;
  background-size: 26px 28px;
  left: 4px;
  top: 57px;
}
.ada31 {
  width: 36px;
  height: 39px;
  background-position: left 4px top 4px;
  background-size: 28px 31px;
  left: -1px;
  top: 27px;
}
.ada32 {
  width: 34px;
  height: 39px;
  background-position: left 4px top 4px;
  background-size: 26px 31px;
  left: -2px;
  top: -2px;
}

.isMarked.ada1 {
  background-image: url('./images/reg16.svg');
}
.isMarked.ada2 {
  background-image: url('./images/reg15.svg');
}
.isMarked.ada3 {
  background-image: url('./images/reg14.svg');
}
.isMarked.ada4 {
  background-image: url('./images/reg13.svg');
}
.isMarked.ada5 {
  background-image: url('./images/reg12.svg');
}
.isMarked.ada6 {
  background-image: url('./images/reg11.svg');
}
.isMarked.ada7 {
  background-image: url('./images/reg10.svg');
}
.isMarked.ada8 {
  background-image: url('./images/reg9.svg');
}
.isMarked.ada9 {
  background-image: url('./images/reg9.svg');
}
.isMarked.ada10 {
  background-image: url('./images/reg10.svg');
}
.isMarked.ada11 {
  background-image: url('./images/reg11.svg');
}
.isMarked.ada12 {
  background-image: url('./images/reg12.svg');
}
.isMarked.ada13 {
  background-image: url('./images/reg13.svg');
}
.isMarked.ada14 {
  background-image: url('./images/reg14.svg');
}
.isMarked.ada15 {
  background-image: url('./images/reg15.svg');
}
.isMarked.ada16 {
  background-image: url('./images/reg16.svg');
}
.isMarked.ada17 {
  background-image: url('./images/reg32.svg');
}
.isMarked.ada18 {
  background-image: url('./images/reg31.svg');
}
.isMarked.ada19 {
  background-image: url('./images/reg30.svg');
}
.isMarked.ada20 {
  background-image: url('./images/reg29.svg');
}
.isMarked.ada21 {
  background-image: url('./images/reg28.svg');
}
.isMarked.ada22 {
  background-image: url('./images/reg27.svg');
}
.isMarked.ada23 {
  background-image: url('./images/reg26.svg');
}
.isMarked.ada24 {
  background-image: url('./images/reg25.svg');
}
.isMarked.ada25 {
  background-image: url('./images/reg25.svg');
}
.isMarked.ada26 {
  background-image: url('./images/reg26.svg');
}
.isMarked.ada27 {
  background-image: url('./images/reg27.svg');
}
.isMarked.ada28 {
  background-image: url('./images/reg28.svg');
}
.isMarked.ada29 {
  background-image: url('./images/reg29.svg');
}
.isMarked.ada30 {
  background-image: url('./images/reg30.svg');
}
.isMarked.ada31 {
  background-image: url('./images/reg31.svg');
}
.isMarked.ada32 {
  background-image: url('./images/reg32.svg');
}

/* tier 2 conflicts */
.hasConflict.ada1 {
  background-image: url('./images/tier2ConflictedTeeth/reg16.png');
}
.hasConflict.ada2 {
  background-image: url('./images/tier2ConflictedTeeth/reg15.png');
}
.hasConflict.ada3 {
  background-image: url('./images/tier2ConflictedTeeth/reg14.png');
}
.hasConflict.ada4 {
  background-image: url('./images/tier2ConflictedTeeth/reg13.png');
}
.hasConflict.ada5 {
  background-image: url('./images/tier2ConflictedTeeth/reg12.png');
}
.hasConflict.ada6 {
  background-image: url('./images/tier2ConflictedTeeth/reg11.png');
}
.hasConflict.ada7 {
  background-image: url('./images/tier2ConflictedTeeth/reg10.png');
}
.hasConflict.ada8 {
  background-image: url('./images/tier2ConflictedTeeth/reg9.png');
}
.hasConflict.ada9 {
  background-image: url('./images/tier2ConflictedTeeth/reg9.png');
}
.hasConflict.ada10 {
  background-image: url('./images/tier2ConflictedTeeth/reg10.png');
}
.hasConflict.ada11 {
  background-image: url('./images/tier2ConflictedTeeth/reg11.png');
}
.hasConflict.ada12 {
  background-image: url('./images/tier2ConflictedTeeth/reg12.png');
}
.hasConflict.ada13 {
  background-image: url('./images/tier2ConflictedTeeth/reg13.png');
}
.hasConflict.ada14 {
  background-image: url('./images/tier2ConflictedTeeth/reg14.png');
}
.hasConflict.ada15 {
  background-image: url('./images/tier2ConflictedTeeth/reg15.png');
}
.hasConflict.ada16 {
  background-image: url('./images/tier2ConflictedTeeth/reg16.png');
}
.hasConflict.ada17 {
  background-image: url('./images/tier2ConflictedTeeth/reg32.png');
}
.hasConflict.ada18 {
  background-image: url('./images/tier2ConflictedTeeth/reg31.png');
}
.hasConflict.ada19 {
  background-image: url('./images/tier2ConflictedTeeth/reg30.png');
}
.hasConflict.ada20 {
  background-image: url('./images/tier2ConflictedTeeth/reg29.png');
}
.hasConflict.ada21 {
  background-image: url('./images/tier2ConflictedTeeth/reg28.png');
}
.hasConflict.ada22 {
  background-image: url('./images/tier2ConflictedTeeth/reg27.png');
}
.hasConflict.ada23 {
  background-image: url('./images/tier2ConflictedTeeth/reg26.png');
}
.hasConflict.ada24 {
  background-image: url('./images/tier2ConflictedTeeth/reg25.png');
}
.hasConflict.ada25 {
  background-image: url('./images/tier2ConflictedTeeth/reg25.png');
}
.hasConflict.ada26 {
  background-image: url('./images/tier2ConflictedTeeth/reg26.png');
}
.hasConflict.ada27 {
  background-image: url('./images/tier2ConflictedTeeth/reg27.png');
}
.hasConflict.ada28 {
  background-image: url('./images/tier2ConflictedTeeth/reg28.png');
}
.hasConflict.ada29 {
  background-image: url('./images/tier2ConflictedTeeth/reg29.png');
}
.hasConflict.ada30 {
  background-image: url('./images/tier2ConflictedTeeth/reg30.png');
}
.hasConflict.ada31 {
  background-image: url('./images/tier2ConflictedTeeth/reg31.png');
}
.hasConflict.ada32 {
  background-image: url('./images/tier2ConflictedTeeth/reg32.png');
}
/*  */

/* tier 2 conflicted + marked teeth */
.hasConflictAndMarked.ada1 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg16.png');
}
.hasConflictAndMarked.ada2 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg15.png');
}
.hasConflictAndMarked.ada3 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg14.png');
}
.hasConflictAndMarked.ada4 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg13.png');
}
.hasConflictAndMarked.ada5 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg12.png');
}
.hasConflictAndMarked.ada6 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg11.png');
}
.hasConflictAndMarked.ada7 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg10.png');
}
.hasConflictAndMarked.ada8 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg9.png');
}
.hasConflictAndMarked.ada9 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg9.png');
}
.hasConflictAndMarked.ada10 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg10.png');
}
.hasConflictAndMarked.ada11 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg11.png');
}
.hasConflictAndMarked.ada12 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg12.png');
}
.hasConflictAndMarked.ada13 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg13.png');
}
.hasConflictAndMarked.ada14 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg14.png');
}
.hasConflictAndMarked.ada15 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg15.png');
}
.hasConflictAndMarked.ada16 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg16.png');
}
.hasConflictAndMarked.ada17 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg32.png');
}
.hasConflictAndMarked.ada18 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg31.png');
}
.hasConflictAndMarked.ada19 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg30.png');
}
.hasConflictAndMarked.ada20 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg29.png');
}
.hasConflictAndMarked.ada21 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg28.png');
}
.hasConflictAndMarked.ada22 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg27.png');
}
.hasConflictAndMarked.ada23 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg26.png');
}
.hasConflictAndMarked.ada24 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg25.png');
}
.hasConflictAndMarked.ada25 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg25.png');
}
.hasConflictAndMarked.ada26 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg26.png');
}
.hasConflictAndMarked.ada27 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg27.png');
}
.hasConflictAndMarked.ada28 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg28.png');
}
.hasConflictAndMarked.ada29 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg29.png');
}
.hasConflictAndMarked.ada30 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg30.png');
}
.hasConflictAndMarked.ada31 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg31.png');
}
.hasConflictAndMarked.ada32 {
  background-image: url('./images/tierTwoConflicedAndMarkedTeeth/reg32.png');
}
/*  */

.isChecked.ada1::after {
  background-image: url('./images/sel16.svg');
  width: 30px;
  height: 36px;
}
.isChecked.ada2::after {
  background-image: url('./images/sel15.svg');
  width: 34px;
  height: 36px;
}
.isChecked.ada3::after {
  background-image: url('./images/sel14.svg');
  width: 33px;
  height: 36px;
}
.isChecked.ada4::after {
  background-image: url('./images/sel13.svg');
  width: 31px;
  height: 32px;
}
.isChecked.ada5::after {
  background-image: url('./images/sel12.svg');
  width: 32px;
  height: 30px;
}
.isChecked.ada6::after {
  background-image: url('./images/sel11.svg');
  width: 33px;
  height: 29px;
}
.isChecked.ada7::after {
  background-image: url('./images/sel10.svg');
  width: 30px;
  height: 29px;
}
.isChecked.ada8::after {
  background-image: url('./images/sel9.svg');
  width: 34px;
  height: 33px;
}
.isChecked.ada9::after {
  background-image: url('./images/sel9.svg');
  width: 34px;
  height: 33px;
}
.isChecked.ada10::after {
  background-image: url('./images/sel10.svg');
  width: 29px;
  height: 30px;
}
.isChecked.ada11::after {
  background-image: url('./images/sel11.svg');
  width: 33px;
  height: 29px;
}
.isChecked.ada12::after {
  background-image: url('./images/sel12.svg');
  width: 32px;
  height: 29px;
}
.isChecked.ada13::after {
  background-image: url('./images/sel13.svg');
  width: 31px;
  height: 32px;
}
.isChecked.ada14::after {
  background-image: url('./images/sel14.svg');
  width: 33px;
  height: 36px;
}
.isChecked.ada15::after {
  background-image: url('./images/sel15.svg');
  width: 34px;
  height: 36px;
}
.isChecked.ada16::after {
  background-image: url('./images/sel16.svg');
  width: 30px;
  height: 36px;
}
.isChecked.ada17::after {
  background-image: url('./images/sel32.svg');
  width: 33px;
  height: 39px;
}
.isChecked.ada18::after {
  background-image: url('./images/sel31.svg');
  width: 36px;
  height: 38px;
}
.isChecked.ada19::after {
  background-image: url('./images/sel30.svg');
  width: 34px;
  height: 36px;
}
.isChecked.ada20::after {
  background-image: url('./images/sel29.svg');
  width: 30px;
  height: 33px;
}
.isChecked.ada21::after {
  background-image: url('./images/sel28.svg');
  width: 32px;
  height: 34px;
}
.isChecked.ada22::after {
  background-image: url('./images/sel27.svg');
  width: 30px;
  height: 30px;
}
.isChecked.ada23::after {
  background-image: url('./images/sel26.svg');
  width: 25px;
  height: 27px;
}
.isChecked.ada24::after {
  background-image: url('./images/sel25.svg');
  width: 27px;
  height: 29px;
}
.isChecked.ada25::after {
  background-image: url('./images/sel25.svg');
  width: 27px;
  height: 29px;
}
.isChecked.ada26::after {
  background-image: url('./images/sel26.svg');
  width: 25px;
  height: 27px;
}
.isChecked.ada27::after {
  background-image: url('./images/sel27.svg');
  width: 30px;
  height: 30px;
}
.isChecked.ada28::after {
  background-image: url('./images/sel28.svg');
  width: 32px;
  height: 34px;
}
.isChecked.ada29::after {
  background-image: url('./images/sel29.svg');
  width: 30px;
  height: 33px;
}
.isChecked.ada30::after {
  background-image: url('./images/sel30.svg');
  width: 34px;
  height: 36px;
}
.isChecked.ada31::after {
  background-image: url('./images/sel31.svg');
  width: 36px;
  height: 38px;
}
.isChecked.ada32::after {
  background-image: url('./images/sel32.svg');
  width: 33px;
  height: 39px;
}

/* adjustments */
.isChecked.ada10::after {
  top: 3px;
}
.isChecked.ada12::after {
  top: 1px;
  left: 3px;
}
.isChecked.ada19::after {
  left: 2px;
}
.isChecked.ada29::after {
  left: 2px;
}

.isDisabled.ada1 {
  background-image: url('./images/dis16.svg');
  width: 31px;
  height: 36px;
  transform: scaleX(-1);
}
.isDisabled.ada2 {
  background-image: url('./images/dis15.svg');
  width: 34px;
  height: 36px;
  transform: scaleX(-1);
}
.isDisabled.ada3 {
  background-image: url('./images/dis14.svg');
  width: 34px;
  height: 36px;
  transform: scaleX(-1);
}
.isDisabled.ada4 {
  background-image: url('./images/dis13.svg');
  width: 31px;
  height: 31px;
  transform: scaleX(-1);
}
.isDisabled.ada5 {
  background-image: url('./images/dis12.svg');
  width: 33px;
  height: 30px;
  transform: scaleX(-1);
}
.isDisabled.ada6 {
  background-image: url('./images/dis11.svg');
  width: 33px;
  height: 29px;
  transform: scaleX(-1);
}
.isDisabled.ada7 {
  background-image: url('./images/dis10.svg');
  width: 29px;
  height: 29px;
  transform: scaleX(-1);
}
.isDisabled.ada8 {
  background-image: url('./images/dis9.svg');
  width: 34px;
  height: 33px;
  transform: scaleX(-1);
}
.isDisabled.ada9 {
  background-image: url('./images/dis9.svg');
  width: 32px;
  height: 33px;
}
.isDisabled.ada10 {
  background-image: url('./images/dis10.svg');
  width: 29px;
  height: 35px;
}
.isDisabled.ada11 {
  background-image: url('./images/dis11.svg');
  width: 33px;
  height: 29px;
}
.isDisabled.ada12 {
  background-image: url('./images/dis12.svg');
  width: 37px;
  height: 30px;
}
.isDisabled.ada13 {
  background-image: url('./images/dis13.svg');
  width: 31px;
  height: 31px;
}
.isDisabled.ada14 {
  background-image: url('./images/dis14.svg');
  width: 34px;
  height: 34px;
}
.isDisabled.ada15 {
  background-image: url('./images/dis15.svg');
  width: 34px;
  height: 36px;
}
.isDisabled.ada16 {
  background-image: url('./images/dis16.svg');
  width: 30px;
  height: 36px;
}
.isDisabled.ada17 {
  background-image: url('./images/dis32.svg');
  width: 34px;
  height: 39px;
  transform: scaleX(-1);
}
.isDisabled.ada18 {
  background-image: url('./images/dis31.svg');
  width: 36px;
  height: 39px;
  transform: scaleX(-1);
}
.isDisabled.ada19 {
  background-image: url('./images/dis30.svg');
  width: 37px;
  height: 36px;
  transform: scaleX(-1);
}
.isDisabled.ada20 {
  background-image: url('./images/dis29.svg');
  width: 32px;
  height: 31px;
  transform: scaleX(-1);
}
.isDisabled.ada21 {
  background-image: url('./images/dis28.svg');
  width: 32px;
  height: 32px;
  transform: scaleX(-1);
}
.isDisabled.ada22 {
  background-image: url('./images/dis27.svg');
  width: 31px;
  height: 27px;
  transform: scaleX(-1);
}
.isDisabled.ada23 {
  background-image: url('./images/dis26.svg');
  width: 26px;
  height: 28px;
  transform: scaleX(-1);
}
.isDisabled.ada24 {
  background-image: url('./images/dis25.svg');
  width: 26px;
  height: 29px;
  transform: scaleX(-1);
}
.isDisabled.ada25 {
  background-image: url('./images/dis25.svg');
  width: 26px;
  height: 29px;
}
.isDisabled.ada26 {
  background-image: url('./images/dis26.svg');
  width: 26px;
  height: 28px;
}
.isDisabled.ada27 {
  background-image: url('./images/dis27.svg');
  width: 31px;
  height: 27px;
}
.isDisabled.ada28 {
  background-image: url('./images/dis28.svg');
  width: 32px;
  height: 32px;
}
.isDisabled.ada29 {
  background-image: url('./images/dis29.svg');
  width: 33px;
  height: 31px;
}
.isDisabled.ada30 {
  background-image: url('./images/dis30.svg');
  width: 34px;
  height: 36px;
}
.isDisabled.ada31 {
  background-image: url('./images/dis31.svg');
  width: 36px;
  height: 39px;
}
.isDisabled.ada32 {
  background-image: url('./images/dis32.svg');
  width: 34px;
  height: 39px;
}

.isChecked::after {
  z-index: 5;
  position: absolute;
  content: '';
  background-size: contain;
  display: block;
  background-repeat: no-repeat;
  left: 0px;
  top: 0px;
}
