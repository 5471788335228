.stage {
  position: absolute;
  bottom: 1px;
}

.selected {
  z-index: 3;
}

.upLayer {
  z-index: 2;
}
