.imageRotationController {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
}

.imageRotationControllerButton {
  height: 40px;
  width: 40px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid rgba(105, 157, 220, 0.8);
  background-color: rgba(105, 157, 220, 0.15);
}

.imageRotationControllerButton:active {
  transform: scale(0.96);
}

.imageRotationControllerButton:focus {
  outline: none;
}

.horizontalIcon {
  transform: rotateY(180deg);
}

.rotateIcon {
  transform: rotateZ(90deg);
}
