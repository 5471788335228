.dictionaryOverlayClass {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.35);
}

.dictionaryModalClass {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 28vw;
  height: 70vh;
  top: 40px;
  left: calc(100% - 20px);
  transform: translate3d(-100%, 0, 0);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
}

.modalHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 60px;
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
}

.modalSubHeader {
  top: 60px;
  border-bottom: 1px solid #e9ecef;
}

.modalHeaderTitle {
  margin: 0;
}

.modalBody {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  flex-grow: 1;
}

.dictionaryDataSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dictionaryDataSectionItem {
  width: 50%;
  font-size: 17px;
}

.modalFooter {
  height: 60px;
  flex-shrink: 0;
  border-top: 1px solid #e9ecef;
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
}

.modalCloseButton {
  background: #ad2323;
  background-image: linear-gradient(to bottom, #ad2323, #d34545);
  border-radius: 10px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 100;
  padding: 10px 20px 10px 20px;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #971313 1px;
  cursor: pointer;
  text-align: center;
}

.modalCloseButton:hover {
  border: solid #861414 1px;
  background: #d01e1e;
  background-image: linear-gradient(to bottom, #d01e1e, #d32929);
  border-radius: 10px;
  text-decoration: none;
}

.modalInputContainer {
  width: calc(100% - 60px);
  position: relative;
}

.modalSearchInput {
  width: 100%;
  height: 36px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid gray;
}

.modalSearchInput:focus {
  outline: none;
}

.inputIcon {
  height: 20px;
  width: 20px;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.pointer {
  cursor: pointer;
}

.dictionaryBtn {
  cursor: pointer;
  background: white;
  border: 1px solid grey;
  overflow: hidden;
  border-radius: 5px;
  top: 20px;
  right: 220px;
  height: 43px;
  width: 43px;
  box-sizing: border-box;
  z-index: 1;
  margin: 5px;
}

.dictionaryBtn:active {
  transform: scale(0.96);
}

.dictionaryBtnImage {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
}
