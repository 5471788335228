.buttonDel {
  background: #ad2323;
  background-image: linear-gradient(to bottom, #ad2323, #d34545);
  margin-right: 10px;
  border-radius: 10px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 100;
  padding: 10px 20px 10px 20px;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #971313 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  width: 85.36px;
  height: 41px;
  margin: 20px 0 0 75px;
}

.buttonDel:hover {
  border: solid #861414 1px;
  background: #d01e1e;
  background-image: linear-gradient(to bottom, #d01e1e, #d32929);
  border-radius: 10px;
  text-decoration: none;
}

.buttonDel:disabled {
  background: #762323;
  color: gray;
}

.buttonDel:disabled:hover {
  border: solid #971313 1px;
}
