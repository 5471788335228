.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tree-outer-wrap {
  max-height: 400px;
  width: 250px;
  border-radius: 5px;
  overflow: auto;
  background-color: none;
}

.tree-inner-wrap {
  flex: 1 1 auto;
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  display: none;
}

.react-dropdown-tree-select .dropdown {
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  width: 100%;
}

.react-dropdown-tree-select {
  font-size: 15px;
}

.react-dropdown-tree-select .dropdown {
  position: static;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
  background: none;
  box-shadow: none;
  border: none;
  position: static;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: initial;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  margin: 0;
  padding: 0;
}

.react-dropdown-tree-select .dropdown .dropdown-content .search {
  border-top: 1px solid #e2e8f0;
  border-bottom: 3px solid #e2e8f0;
  padding: 0.5rem;
  position: absolute;
  transform: translateY(-2.875rem);
  top: 0;
  right: 0;
  left: 0;
  z-index: 15;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
  padding: 0.25rem;
}

.search {
  display: none;
}

.dateSelection {
  padding: 0.5rem;
  width: 50%;
  font-family: Arial;
  font-size: 15px;
}

.labelsTree {
  width: 40%;
  font-size: 15px;
}

.variantsTree {
  width: 60%;
  font-size: 15px;
}

.dateLabel {
  margin-top: 10px;
}
