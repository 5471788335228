.toggleWrapper {
  padding: 10px 0 10px 10px;
  width: 65%;
  display: flex;
}

.commitWrapper {
  padding: 10px;
  width: 50%;
  display: flex;
}

.flexContainer {
  flex: 1;
}
