.btn {
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background: #f1efef;
  border: 1px solid grey;
  position: absolute;
  bottom: 4%;
  right: 65%;
}
