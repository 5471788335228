.formcontainer {
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.input {
  width: 65%;
}

.formcontainer button {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin: 10px;
}

.formcontainer button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.linkButton {
  background: aqua;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 45%;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
  border: 2px solid #007bff;
  color: #007bff;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 20px;
}

/* .groupSelect{
  display:flex;}

   */
