.formcontainer button {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  /* font-weight: bold; */
  border: none;
  cursor: pointer;
}

.formcontainer {
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.formcontainer button:hover {
  background-color: #45a049;
}
