.searchNickNameBtn {
  order: 3;
  background: #eee;
  border-color: rgb(170, 170, 170);
  color: #037082;
  border-radius: 5px;
  font-size: 14px;
}

.search {
  display: flex;
  margin-bottom: 5px;
  margin-top: -35px;
  justify-content: space-between;
}

.searchText {
  order: 2;
}
