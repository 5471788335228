.container {
  display: flex;
  height: 100%;
}

.container > .sliders {
  display: flex;
  align-items: stretch;
}

.container > .sliders > div {
  flex-grow: 1;
  border: solid 1px #c3c3c3;
  padding: 5px;
}

.rotate {
  transform: translate(540px) rotate(90deg);
  transform-origin: left top;
}

.fullHeight {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullHeight > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.relative {
  position: relative;
  display: flex;
}

.relativeImg {
  justify-content: center;
  align-items: center;
}

.xrayContainer {
  min-height: 850px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* tier 2 conflict menu */
.tierTwoTagsContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: none;
}

.tierTwoMenuActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conflictedTeethWrapper {
  box-sizing: border-box;
  overflow-y: auto;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
  width: calc(100% - 30px);
  max-height: 50%;
}

.nonConflictedTeethWrapper {
  box-sizing: border-box;
  overflow-y: auto;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
  width: calc(100% - 30px);
  max-height: 50%;
}

.innerTag {
  margin: 5px 0;
  font-size: 18px;
}
