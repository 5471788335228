.container {
  display: flex;
  max-width: 150px;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
}
.containerVertical {
  flex-direction: column;
  max-width: 25px;
}

.containerHorizontal {
  flex-direction: row;
  min-height: 10px;
  max-width: 400px;
}

.iconHorizontal {
  margin-right: 20px;
  margin-top: 5px;
}

.sliderContainerVertical {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.sliderContainerHorizontal {
  width: 100%;
}
.container :global .rc-slider {
  max-width: 100px;
}

.container :global .rc-slider-vertical {
  flex: 1;
}

.container :global .rc-slider-track {
  background-color: #0067ac;
}

.container :global .rc-slider-handle {
  border: solid 7px #00adef;
}

.disabledContainer :global .rc-slider-track {
  background-color: #a1b2bd;
}

.disabledContainer :global .rc-slider-handle {
  border: solid 7px #689fb5;
}

.container :global .rc-slider-handle:focus {
  box-shadow: none;
}
