.buttonCdn {
  background: #0e9acf;
  background-image: linear-gradient(to bottom, #1990bd, #00b9ff);
  border-radius: 10px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 100;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #026170 1px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  height: 35px;
  display: flex;
  align-items: center;
  width: 120px;
  margin-left: 45.5%;
}

.buttonCdn:hover {
  border: solid #06516c 1px;
  background: #3dd1ee;
  background-image: linear-gradient(to bottom, #1b9fcf, #3ecef6);
  border-radius: 10px;
  text-decoration: none;
}
.buttonCdn:disabled {
  background: #06516c;
  color: gray;
}

.sliderContainer {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
