.sidebar {
  top: 70.23px;
  margin: 0;
  padding: 0;
  width: 18%;
  height: calc(100vh - 70.23px);
  overflow: auto;
  border-right: 1px solid rgb(155, 155, 155);
}

.flex {
  display: flex;
}

.sidebarParagraph {
  padding-left: 20px;
  color: #037082;
  font-weight: 500;
  font-size: 20px;
}

.goToBtn {
  position: absolute;
  bottom: 4%;
  right: 3%;
}
.unTakeBtn {
  position: absolute;
  bottom: 4%;
  right: 20%;
}
.btn {
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background: #f1efef;
  border: 1px solid grey;
}

.labeler {
  margin-top: 30px;
  padding: 10px;
  display: flex;
}

.variantsTable {
  margin-left: 4%;
}

.marginLeft {
  margin-left: 30%;
}

.marginTop {
  position: absolute;
  top: 670px;
  margin-left: 66px;
}

.deletedTable {
  margin-right: 20px;
}

.paragraph {
  color: #037082;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.pullTask {
  margin: 10px;
}
.hidden {
  visibility: hidden;
}

.hourglass {
  margin: 12%;
  margin-top: 10px;
}

.center {
  position: fixed;
  top: 230px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nextTask {
  width: 145px;
}

.specificTaskText {
  width: 100px;
  margin: 10px;
}

.tableContainer {
  margin-top: 30px;
}

.version {
  color: #037082;
  font-weight: 500;
  z-index: 100;
  position: absolute;
  bottom: 2%;
  left: 2%;
}
