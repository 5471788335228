.supervisorNotesNavigationsArrowButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 10%;
}

.emptyNotesList {
  background-color: lightgray;
}
