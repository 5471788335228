.root {
  height: 100%;
  width: 100%;
  background: #f2f7fa;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
