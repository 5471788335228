.listItem {
  text-align: start;
  padding: 5px;
  cursor: pointer;
  background: white;
  width: 100%;
  white-space: nowrap;
  font-size: 13px;
}

.listItem:hover {
  background: #1e90ff;
  color: white;
}

.openSelect {
  position: absolute;
  z-index: 10;
  top: 0;
  left: calc(100% + 50px);
  overflow: hidden;
  width: 220px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.listItem:focus {
  outline: 0;
  background: #1e90ff;
  color: white;
}
